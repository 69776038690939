import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import 'normalize.css';
import ElementUI from 'element-ui';
import '@/styles/element-variables.scss'
import '@/styles/style.scss'
import '@/permissions'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import vueVimeoPlayer from 'vue-vimeo-player'
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(TextareaAutosize);
Vue.use(vueVimeoPlayer)


locale.use(lang)

import Lottie from 'vue-lottie';
Vue.component('lottie', Lottie);


Vue.config.productionTip = false;
Vue.use(ElementUI, {})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
