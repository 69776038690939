






import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from './store/modules/appState';
import './directives';

@Component({
  name: 'App',
  components: {},
})
export default class extends Vue {
  constructor() {
    super();
  }
}
