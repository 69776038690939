import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators';
import store from '@/store';
import { forgot, getUserInfo, login, logout, updatePassword } from '@/api/auth';

import {
  getLocalStorageItem,
  getToken,
  removeLocalStorageItem,
  setLocalStorageItem
} from '@/utils/localStorage';
import { MessageBox } from 'element-ui';
import jwtDecode from 'jwt-decode';

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule {
  public isLoggedIn = false;
  public roles: string[] = [];
  public token = getToken() || '';

  @Mutation
  private SET_AUTH(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Action
  public SetAuth(isLoggedIn: boolean) {
    this.SET_AUTH(isLoggedIn);
  }

  @Action
  public SetToken(token: string) {
    this.SET_TOKEN(token);
  }

  @Action
  public SetRoles(isAdmin: boolean) {
    this.SET_ROLES(isAdmin ? ['admin', 'user'] : ['user']);
  }

  @Action({})
  public async Login(userInfo: { email: string; password: string }) {
    const { email, password } = userInfo;
    const response = await login({ email, password });
    if (response && response.data.token) {
      const roles = response.data.isAdmin ? ['admin' /* , 'user' */] : ['user'];
      setLocalStorageItem('token', response.data.token);
      setLocalStorageItem('userID', response.data.id);
      this.SET_TOKEN(response.data.token);
      this.SET_AUTH(true);
      this.SET_ROLES(roles);
    } else {
      this.SET_AUTH(false);
    }

    return response;
  }

  @Action
  public async Logout() {
    await logout();

    removeLocalStorageItem('token');
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_AUTH(false);
  }

  @Action
  public async Forgot(userInfo: { email: string }) {
    const response = await forgot(userInfo);
    if (response && response.data) {
      MessageBox.alert('To proceed, please check your email', 'Info', {
        confirmButtonText: 'Ok',
        type: 'info'
      }).then(() => {
        location.pathname = '/login';
      });
    }
  }

  @Action({})
  public async Reset(userInfo: {
    password: string;
    hash: string;
    email: string;
  }) {
    const { password, hash, email } = userInfo;

    try {
      await updatePassword({ password, hash, email });
    } catch (e) {
      return console.error(e);
    }

    await MessageBox.alert('Password changed successful', 'Info', {
      confirmButtonText: 'Ok',
      type: 'info'
    });

    location.pathname = '/login';
  }

  @Action({})
  public async GetUserInfo() {
    if (this.token === '') throw Error('GetUserInfo: token is undefined!');

    const { data } = await getUserInfo(this.UserID);
    if (data) this.SET_ROLES(['admin']);
  }

  get UserName() {
    const token = getLocalStorageItem('token');
    if (!token) return '';

    const decoded = jwtDecode(token);
    return !decoded ? '' : decoded.fullname || decoded.name;
  }

  get UserID(): number {
    return Number(getLocalStorageItem('userID'));
  }
}

export const AppModule = getModule(App);
