import request from '@/utils/request';
import {
  IAuthResponse,
  IForgotForm,
  ILoginForm,
  ISuccesResponse
} from '@/models/auth';
import { AxiosPromise } from 'axios';

export const login = (data: ILoginForm): AxiosPromise<IAuthResponse> =>
  request({
    url: '/auth/login',
    method: 'post',
    data
  });

export const logout = (): AxiosPromise<ISuccesResponse> =>
  request({
    url: '/auth/logout',
    method: 'post'
  });

export const forgot = (data: IForgotForm): AxiosPromise<ISuccesResponse> =>
  request({
    url: '/auth/forgot',
    method: 'post',
    data
  });

export const updatePassword = (data: {
  password: string;
  hash: string;
  email: string;
}): AxiosPromise<ISuccesResponse> =>
  request({
    url: '/auth/updatePassword',
    method: 'post',
    data
  });
export const checkResetToken = (data: {
  email: string;
  hash: string;
}): AxiosPromise<ISuccesResponse> =>
  request({
    url: '/auth/reset',
    method: 'post',
    data
  });
export const getUserInfo = (userID: number): AxiosPromise<IAuthResponse> =>
  request({
    url: '/user/getUserInfo',
    method: 'post',
    data: { userID: userID }
  });
