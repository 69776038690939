import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { MessageBox } from 'element-ui';
import { AppModule } from '@/store/modules/appState';
import {
  getLocalStorageItem,
  removeLocalStorageItem
} from '@/utils/localStorage';

const service = axios.create({
  baseURL: '/api',
  timeout: 30000
});

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getLocalStorageItem('token');
    if (token) {
      AppModule.SetToken(token);
      config.headers['authorization'] = 'Bearer ' + AppModule.token;
    }
    return config;
  },
  (error: Error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    let errorMessage = 'Unknown error';
    if (error.response) {
      errorMessage = error.response.data.message;
    }
    if (error && error.response) {
      switch (error.response.status) {
        case 401: {
          removeLocalStorageItem('token');
        }
      }
    }
    MessageBox.alert(errorMessage, 'Error', {
      confirmButtonText: 'Ok',
      type: 'warning'
    })
      .then(() => {
        if (error && error.response) {
          switch (error.response.status) {
            case 401:
              {
                removeLocalStorageItem('token');
              }
              break;
            case 403:
              {
                return location.replace('/');
              }
              break;
          }
        }
        location.reload(); // To prevent bugs from vue-router
      })
      .catch(() => {
        //
      });
    return Promise.reject(error);
  }
);

export default service;
