const PREFIX = 'ds-';

export const getLocalStorageItem = (key: string) => {
  try {
    return localStorage.getItem(PREFIX + key);
  } catch (e) {
    console.error(e);
  }

  return null;
};
// eslint-disable-next-line
export const setLocalStorageItem = (key: string, value: any) => {
  try {
    localStorage.setItem(
      PREFIX + key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  } catch {
    console.log('Error');
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(PREFIX + key);
  } catch {
    console.log('Error');
  }
};

export const getToken = () => {
  return getLocalStorageItem('token');
};
