import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { AppModule } from '@/store/modules/appState';
import jwtDecode from 'jwt-decode';

Vue.use(Router);

const DEFAULT_TITLE = '312.school';

export const constantRoutes: Array<RouteConfig> = [
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: DEFAULT_TITLE || 'Register',
      roles: ['admin', 'user']
    },
    component: () =>
      import(/* webpackChunkName: 'auth' */ '../views/auth/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: DEFAULT_TITLE || 'Login',
      roles: ['admin', 'user']
    },
    component: () =>
      import(/* webpackChunkName: 'auth' */ '../views/auth/Login.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    meta: {
      title: DEFAULT_TITLE || 'Forgot',
      roles: ['admin', 'user']
    },
    component: () =>
      import(/* webpackChunkName: 'auth' */ '../views/auth/Forgot.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    meta: {
      title: DEFAULT_TITLE || 'Reset password',
      roles: ['admin', 'user']
    },
    component: () =>
      import(/* webpackChunkName: 'auth' */ '../views/auth/Reset.vue')
  }
];

export const permissionRoutes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/admin',
    meta: {
      roles: ['admin', 'user']
    }
  },
  {
    path: '/admin',
    component: () =>
      import(/* webpackChunkName: 'admin' */ '@/views/Layout.vue'),
    redirect: '/admin/dashboard',
    meta: {
      title: DEFAULT_TITLE || 'Dashboard',
      roles: ['admin']
    },
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () =>
          import(/* webpackChunkName: 'admin' */ '@/views/admin/Dashboard.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Admin Dashboard',
          roles: ['admin' /* , 'user' */]
        }
      },
      {
        path: 'users',
        name: 'Users',
        component: () =>
          import(/* webpackChunkName: 'admin' */ '@/views/admin/Users.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Users',
          roles: ['admin' /* , 'user' */]
        }
      },
      {
        path: 'users/:id',
        name: 'Stats',
        component: () =>
          import(/* webpackChunkName: 'admin' */ '@/views/admin/UserStats.vue'),
        meta: {
          parent: { url: '/users', title: DEFAULT_TITLE || 'Users' },
          title: DEFAULT_TITLE || 'User Stats',
          roles: ['admin' /* , 'user' */]
        }
      },
      {
        path: 'groups',
        name: 'Groups',
        component: () =>
          import(/* webpackChunkName: 'admin' */ '@/views/admin/Groups.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Groups',
          roles: ['admin' /* , 'user' */]
        }
      },
      {
        path: 'courses',
        name: 'Courses',
        component: () =>
          import(/* webpackChunkName: 'admin' */ '@/views/admin/Courses.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Courses',
          roles: ['admin' /* , 'user' */]
        }
      },
      {
        path: 'courses/lab/:id/challenges',
        name: 'Challenges',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '@/views/admin/Challenges.vue'
          ),
        meta: {
          parent: { url: '/courses', title: DEFAULT_TITLE || 'Courses' },
          title: DEFAULT_TITLE || 'Challenges',
          roles: ['admin' /* , 'user' */]
        }
      }
    ]
  },
  {
    path: '/user',
    component: () =>
      import(/* webpackChunkName: 'user' */ '@/views/UserLayout.vue'),
    redirect: '/user/dashboard',
    meta: {
      title: DEFAULT_TITLE || 'Dashboard',
      roles: ['admin', 'user']
    },
    children: [
      {
        path: 'dashboard',
        name: 'UserDashboard',
        component: () =>
          import(
            /* webpackChunkName: 'user' */ '@/views/user/UserDashboard.vue'
          ),
        meta: {
          title: DEFAULT_TITLE || 'Dashboard',
          roles: ['admin', 'user']
        }
      },
      {
        path: 'course/:id',
        name: 'CourseView',
        component: () =>
          import(/* webpackChunkName: 'user' */ '@/views/user/Course.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Dashboard',
          roles: ['admin', 'user']
        }
      },
      {
        path: 'lab/:id',
        name: 'LabView',
        component: () =>
          import(/* webpackChunkName: 'user' */ '@/views/user/Lab.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Dashboard',
          roles: ['admin', 'user']
        }
      },

      //Create routes;
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import(/* webpackChunkName: 'user' */ '@/views/user/Profile.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Dashboard',
          roles: ['admin', 'user']
        }
      },
      {
        path: 'result/:id/:log?',
        name: 'Result',
        props: true,
        component: () =>
          import(/* webpackChunkName: 'user' */ '@/views/user/LabResult.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Result',
          roles: ['admin', 'user']
        }
      },
      {
        path: 'history/:id',
        name: 'History',
        props: true,
        component: () =>
          import(/* webpackChunkName: 'user' */ '@/views/user/History.vue'),
        meta: {
          title: DEFAULT_TITLE || 'Statistics',
          roles: ['admin', 'user']
        }
      }
    ]
  }
];
const createRouter = (): Router => {
  try {
    const decoded = jwtDecode(localStorage.getItem('ds-token'));
    AppModule.SetRoles(decoded.admin);
  } catch (e) {
    console.error(e);
  }

  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...permissionRoutes, ...constantRoutes]
  });
};

const router = createRouter();

export default router;
