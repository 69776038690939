import { AppModule } from '@/store/modules/appState';
import { CoursesModule } from '@/store/modules/coursesState';
import { DashboardModule } from '@/store/modules/dashboardState';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';
import { UserDashboardModule } from '@/store/modules/userDashboardState';
import { UsersModule } from '@/store/modules/usersState';
import { LabHistoryModule } from '@/store/modules/labHistoryState';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface IRootState {
  app: typeof AppModule;
  users: typeof UsersModule;
  groups: typeof GroupsModule;
  courses: typeof CoursesModule;
  dashboard: typeof DashboardModule;
  dialogs: typeof DialogModule;
  userDashboard: typeof UserDashboardModule;
  labHistory: typeof LabHistoryModule;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
