import { Vue } from 'vue-property-decorator';

const numClass = 'numeric_lines';
const toPx = (value) => {
    return +value.toString().replace('px', '')
}
const addNumLines = (elem, count, lineHeight, fontSize) => {
    let text = '';
    let i=1
    for (;i<=count;i++) {
        text += '<i>'+i+'</i> ';
    }
    let elemLines = elem.querySelector('.'+numClass);
    if (!elemLines) {
        elemLines = document.createElement('div');
        elemLines.classList.add(numClass);
        elemLines.style.lineHeight = lineHeight;
        elemLines.style.position = 'absolute';
        elemLines.style.width = '30px';
        elemLines.style.height = '100%';
        elemLines.style.display = 'block';
        elemLines.style.top = '0';
        elemLines.style.left = '0';
        elemLines.style.textAlign = 'right';
        elemLines.style.paddingTop = '6px';
        elemLines.style.fontSize = (fontSize-2)+'px';
        elemLines.style.backgroundColor = '#efefef';
        elemLines.style.borderRight = '1px solid #e0e0e0';
        elem.append(elemLines);
    }

    elemLines.innerHTML = text;
}

Vue.directive('numericlines', {
  inserted: function (el) {
    new ResizeObserver(() => {
        const style = window.getComputedStyle(el);
        const containerHeight = el.clientHeight;
        if (containerHeight == 0) {
            return;
        }
        const lineHeight = style.getPropertyValue('line-height');
        const fontSize = style.getPropertyValue('font-size');
        const countNums = Math.ceil(containerHeight/toPx(lineHeight));

        addNumLines(el, countNums, lineHeight, toPx(fontSize));
    }).observe(el);
  }
})