import { Route, RouteConfig } from 'vue-router';
import { AppModule } from '@/store/modules/appState';
import { checkResetToken } from '@/api/auth';
import router, { constantRoutes, permissionRoutes } from './router';

const whiteList = ['/login', '/register', '/forgot', '/reset'];

const hasPermission = (roles: string[], route: RouteConfig) => {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
};
let res: RouteConfig[] = [];
export const filterRoutes = (routes: RouteConfig[], roles: string[]) => {
  routes.forEach((route) => {
    const r = { ...route };

    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  res = [];
  if (AppModule.token) {
    AppModule.SetAuth(true);
    if (whiteList.indexOf(to.path) !== -1) {
      next({ path: '/' });
    } else {

      if (!AppModule.roles || AppModule.roles.length === 0) {
       /*  try {
          //await AppModule.GetUserInfo();
        } catch (err) {
          console.log('error');
        } */
      }

      const roles = AppModule.roles;
      const accessedRoutes = filterRoutes(
        [...constantRoutes, ...permissionRoutes],
        roles
      );

      const hasAccess = accessedRoutes.find((route) => {
        return route.name === to.name;
      });

      if (hasAccess || to.path === '/') {
        next();
      } else {
        // AppModule.Logout();
        next( { path: '/user' } );
      }
    }
  } else {
    if (to.path === '/reset') {
      const data = {
        email: to.query.email.toString(),
        hash: to.query.token.toString(),
      };
      if (to.query.token && to.query.email) {
        await checkResetToken(data)
          .then((response) => {
            if (response) {
              next();
            }
          })
          .catch(() => {
            next(`/login?redirect=${to.path}`);
          });
      } else {
        next(`/login?redirect=${to.path}`);
      }
    }
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach((to: Route) => {
  // set page title
  document.title = to.meta.title;
});
